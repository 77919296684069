import $ from "jquery";
import {handleValidation, validateField} from "../form.js";

/** Initialize name fields. */
export function initializeNameFields() {
    $("input[name=firstName]").each(function () {
        const firstNameField = $(this);
        const placeholder = firstNameField.prop("placeholder") || "First Name";
        firstNameField
            .data("val-required", true)
            .prop("pattern", "^(?=.*[a-zA-Z])[a-zA-Z0-9 \\-,.']{1,50}$")
            .prop("minlength", "1")
            .prop("maxlength", "50")
            .prop("placeholder", placeholder)
            .prop("aria-label", "Your first name")
            .prop("autocomplete", "given-name");

        const id = firstNameField.prop("id");
        const errorElementId = id + "-error";
        let errorElement = $("#" + errorElementId);

        if (errorElement.length === 0) {
            errorElement = $("<div>", {
                "id": errorElementId,
                "data-selector": errorElementId,
                class: "invalid-feedback text-end",
                html: "Please enter your first name",
            }).insertAfter(firstNameField);
        }

        firstNameField.on("change", function () {
            handleValidation(firstNameField, errorElement);
        });

        if (validateField(firstNameField)) {
            firstNameField.trigger("change");
        }
    });

    $("input[name=lastName]").each(function () {
        const lastNameField = $(this);
        const placeholder = lastNameField.prop("placeholder") || "Last Name";
        lastNameField
            .data("val-required", true)
            .prop("pattern", "^(?=.*[a-zA-Z])[a-zA-Z0-9 \\-,.']{1,50}$")
            .prop("minlength", "1")
            .prop("maxlength", "50")
            .prop("placeholder", placeholder)
            .prop("aria-label", "Your last name")
            .prop("autocomplete", "family-name");

        const id = lastNameField.prop("id");
        const errorElementId = id + "-error";
        let errorElement = $("#" + errorElementId);

        if (errorElement.length === 0) {
            errorElement = $("<div>", {
                "id": errorElementId,
                "data-selector": errorElementId,
                class: "invalid-feedback text-end",
                html: "Please enter your last name",
            }).insertAfter(lastNameField);
        }

        lastNameField.on("change", function () {
            handleValidation(lastNameField, errorElement);
        });

        if (validateField(lastNameField)) {
            lastNameField.trigger("change");
        }
    });

    $("input[name=name]").each(function () {
        const nameField = $(this);
        const placeholder = nameField.prop("placeholder") || "Full Name";
        nameField
            .data("val-required", true)
            .prop("pattern", "^(?=.*[a-zA-Z])[a-zA-Z0-9 \\-,.']{1,50}$")
            .prop("minlength", "1")
            .prop("maxlength", "50")
            .prop("placeholder", placeholder)
            .prop("aria-label", "Your full name")
            .prop("autocomplete", "name");
        $("<div>", {
            "data-selector": "name-error",
            class: "invalid-feedback text-end",
            html: "Please enter your full name",
        }).insertAfter(nameField);
    });

}
